import React from "react"

// Googlen redirect ohje:
// https://developers.google.com/search/docs/advanced/crawling/301-redirects#metarefresh

const RedirectPage = ({ pageContext }) => {
  //console.log("Rendering RedirectPage", pageContext)

  const url = pageContext.redirect

  return (
    <p>
      Content moved to <a href={url}>{url}</a>
    </p>
  )
}
export default RedirectPage

export function Head({ pageContext }) {
  const url = pageContext.redirect
  // TODO: pitäisikö lisätä robots metaa: https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
  /** delay=0 Google tulkitsee "permanent redirect". delay>0 Google tulkitsee "temporary redirect" */
  return (
    <>
      <meta httpEquiv="refresh" content={`0; url=${url}`} />
      <meta name="robots" content="noindex" />
      <link rel="canonical" href={url} />
      <title>Redirecting to {url}</title>
    </>
  )
}
